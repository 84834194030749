<template>
	<v-card max-width="800" class="mx-auto mt-5" :loading="loading || loadingParent" rounded="xl">
		<v-card-title>{{ $t('companies.inviteAdmin') }}</v-card-title>
		<v-card-text>
			<v-list v-cloak two-line subheader>
				<v-subheader>
					{{
						$t('companies.adminsInUse', {
							currentNumber: company.administrators.administrators.length,
							maxNumber: company.administrators.maxPurchased
						})
					}}
				</v-subheader>

				<v-list-item v-for="admin in company.administrators.administrators" :key="admin.id">
					<v-list-item-avatar>
						<v-icon v-if="admin.invited">mdi-account-plus-outline</v-icon>
						<v-icon v-else>mdi-shield-account</v-icon>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>{{ admin.name.full }}</v-list-item-title>
						<v-list-item-subtitle>
							{{ admin.email }} <span v-if="admin.invited">[{{ $t('companies.invited') }}]</span>
						</v-list-item-subtitle>
					</v-list-item-content>

					<v-list-item-action>
						<v-btn icon @click="admin.invited ? callCancelInvitation(admin.id) : callRemoveAdmin(admin.id)">
							<v-icon color="red">
								mdi-close-circle
							</v-icon>
						</v-btn>
					</v-list-item-action>
				</v-list-item>

				<CompanyAdministratorsInviteForm :loading-parent="loadingParent || loading" />
			</v-list>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CompanyAdministrators',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		CompanyAdministratorsInviteForm: () => import('@/components/companies/CompanyAdministratorsInviteForm.vue')
	},
	computed: {
		joinedAdministrators() {
			return this.company.administrators.administrators.filter((admin) => admin.joined)
		},
		invitedAdministrators() {
			return this.company.administrators.administrators.filter((admin) => admin.invited)
		},
		...mapGetters({
			company: 'companies/company'
		})
	},
	methods: {
		callCancelInvitation(employerID) {
			this.cancelInvitation({ companyID: this.company.id, employerID })
		},
		callRemoveAdmin(employerID) {
			this.removeAdmin({ companyID: this.company.id, employerID })
		},
		...mapActions('companies', ['cancelInvitation', 'removeAdmin'])
	}
}
</script>
